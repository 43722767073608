import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { Icon } from '../../../common/icon'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Frage8() {
  const question = {
    title: 'Frage 8 von 35',
    headline: '',
    subline:
      'Wie wichtig ist es dir, eine Beziehung zu deinen Patienten aufzubauen?',
  }

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-turkis-waves">
        <Navigation />

        <section className="quiz">
          <div className="row text-center">
            <MatchingQuestion
              color="turkis"
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="range"
              color="yellow"
              from="Steht für mich im Vordergrund"
              to="Steht für mich nicht im Vordergrund"
              index={8}
            />
          </div>
        </section>

        <MatchingNavigation
          color="turkis"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/frage-9`}
          prev={`${AREA_CARE_PATH}/spielen/frage-7`}
          index={8}
        />

        <Icon position="4" name="conversation" />
      </main>
    </>
  )
}
